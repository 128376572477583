#tab-selector-container {
    width: 100%;
    height: 54px;
    background-color: red;
}
#tab-selector-tabs {
    height: 45px;
    overflow: hidden;
    display: flex;
}
#tab-selector-tab-left {
    display: flex;
    width: calc(100% - 180px);
    overflow: hidden;
    .tab {
        .tab-text {
            border-right: 2px solid red
        }
    }
    .tab.selected {
        .tab-text {
            border-right: 2px solid red
        }
    }
}
#tab-selector-tab-right {
    display: flex;
    width: 180px;
    .tab {
        .tab-text {
            border-left: 2px solid red
        }
    }
    .tab.selected {
        .tab-text {
            border-left: 2px solid red
        }
    }
}
.tab {
    width: 90px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .tab-text {
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}
.tab.selected {
    background-color: red;
    transition: background-color 0.3s ease-out;
    color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
#tab-selector-bottom-line {
    height: 0px;
    border-top: 7px solid red;
    border-bottom: 2px solid red;
}
.table-select-dialog {
    .btn {
        margin: 7px;
    }
}