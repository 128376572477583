.sell-modul-modal-root {
  display: flex;
  flex-direction: column;
  width: 836px;
  height: 282px;
  justify-content: space-between;
  .sell-modal-title {
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }
  .sell-modal-body {
    padding-left: 55px;
    font-weight: 500;
  }
  .sell-modal-cost {
    padding-left: 55px;
    font-weight: 700;
  }
  .sell-modal-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    .sell-modal-button {
      font-weight: 700;
      padding: 10px 30px;
      background: var(--ten-color);
      border-radius: 5px;
    }
  }
}
