.plus-minus-field-container {
    position: relative;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content;
    grid-template-areas: "left middle right";
    width: 100%;
    margin: auto;
    background-color: var(--btn-bg-color);
    border-radius: 300px;
}
.plus-minus-field-bg {
    position: absolute;
    z-index: 0;
    svg {
        width: 100%;
    }
}
.plus-minus-field-count {
    z-index: 1;
    grid-area: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    color: #fff;
}
.plus-minus-field-plus {
    z-index: 1;
    grid-area: right;
    border: 0px solid;
    width: 30px;
    height: 30px;
    svg {
        .svgPassiveBorder {
            fill: red;
        }
        .svgActiveBg, .svgPassiveBg {
            fill: red;
        }
        .svgActiveBorder {
            fill: rgba(0,0,0,0)
        }
        .svgActiveText, .svgPassiveText {
            fill: #fff
        }
        width: 100%;
        height: 100%;
    }
}
.plus-minus-field-minus {
    z-index: 1;
    grid-area: left;
    border: 0px solid;
    width: 30px;
    height: 30px;
    svg {
        .svgPassiveBorder {
            fill: red;
        }
        .svgActiveBg, .svgPassiveBg {
            fill: red;
        }
        .svgActiveBorder {
            fill: rgba(0,0,0,0);
        }
        .svgActiveText, .svgPassiveText {
            fill: #fff;
        }
        width: 100%;
        height: 100%;
    }
}
.to-basket-btn {
    border-radius: 30px;
    display: flex;
    width: 100%;
    min-width: 85px;
    height: 100%;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .to-basket-btn-icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .arrow {
            height: 2em;
            margin: 0px;
            svg {
                height: 100%;
            }
        }
        .cart-icon {
            margin: 0px;
            height: 2em;
            color: #fff;
            //padding: 3px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                height: 80%;

                /*height: 20px;
                width: 20px;*/
            }
        }
    }
}
.to-basket-btn-with-price {
    padding: 0.25em 0 0 0;
    border-radius: 7px;
    .to-basket-btn-price {
        text-align: center;
    }
}