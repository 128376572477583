.tutorial-layer {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    pointer-events: none;
    .not-visible {
        opacity: 0;
        pointer-events: none;
    }
    .visible {
        pointer-events: auto;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .center-root {
        padding: 0;
        position: absolute;
        z-index: 5;
        top: var(--header-height);
        left: var(--left-side-width);
        width: calc(100% - (300px + var(--left-side-width)));
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .summary-root {
        position: absolute;
        top: var(--header-height);
        right: 0;
        width: 300px;
        height: calc(100% - var(--header-height));
    }
    .tutorial-exit-btn {
        position: absolute;
        left: 64px;
        bottom: 24px;
        z-index: 999;
        border: 1px solid var(--border-color);
        padding: 12px;
        border-radius: 5px;
        background-color: var(--thirty-color);
        cursor: pointer;
        pointer-events: all;
    }
}
.tutorial-ended-modal {
    .title {
        font-size: 24px;
        text-align: center;
        margin-bottom: 12px;
    }
    .body {
        max-width: 300px;
        text-align: center;
        margin-bottom: 12px;
    }
    .actions {
        text-align: center;
    }
}