.transactions-root {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: auto;
}
.loading-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  &.hiddenOpacity {
    opacity: 0;
    pointer-events: none;
  }
}
.tansactions-filter-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: auto;
  padding: 12px;
  min-width: 300px;
  position: relative;
  .date-selector {
    margin-bottom: 1em;
    .textField-root.disabled {
      opacity: 1;
      .textField-label,
      .textField-base {
        background: var(--sixty-color);
        color: var(--text-color);
      }
    }
  }
  .group-container {
    width: 100%;
    margin-bottom: 1em;
    .title {
      text-align: center;
      font-size: 22px;
      margin-bottom: 0.5em;
    }
    .options-container {
      width: 100%;
      margin-bottom: 12px;
      display: flex;
      flex-direction: column;
      gap: 12px;
      row-gap: 12px;
      column-gap: 12px;
      &.device-types {
        flex-direction: row;
        justify-content: space-around;
        .type-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;
          row-gap: 6px;
          column-gap: 6px;
          height: 75px;
          width: 75px;
          border: 1px solid var(--border-color);
          border-radius: 5px;
          padding: 6px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          background: var(--thirty-color);
          &.selected {
            background: var(--ten-color);
            color: #fff;
            svg {
              .svgFill {
                fill: #fff;
              }
            }
          }
          svg {
            width: 48px;
            height: 48px;
          }
          .text {
            font-size: 12px;
          }
        }
      }
      .textField-root {
        width: 100%;
        .textField-label {
          color: var(--text-color);
        }
        .textField-base {
          color: var(--text-color);
        }
      }
    }
  }
}
.orders-root {
  background: var(--thirty-color);
  min-width: 350px;
  max-width: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 12px 12px;
  border-left: 2px solid var(--border-color);
  border-right: 2px solid var(--border-color);
  .title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 0.5em;
    padding-top: 0.5em;
  }
  .orders-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: auto;
    gap: 12px;
    row-gap: 12px;
    column-gap: 12px;
    padding-bottom: 12px;
    .order-row {
      width: 95%;
      //justify-content: space-between;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-shrink: 0;
      background: var(--sixty-color);
      border-radius: 5px;
      padding: 12px;
      border: solid 2px var(--border-color);
      overflow: hidden;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      &.selected {
        background: var(--ten-color);
        color: #fff;
      }
      .left-side {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        min-width: 115px;
        min-height: 50px;
        .order-number {
          font-weight: bold;
          font-size: 22px;
        }
        .date {
          font-size: 14px;
          font-style: italic;
        }
      }
      .right-side {
        min-width: 70px;
        min-height: 50px;
        flex-grow: 1;
        text-align: right;
        display: flex;
        flex-direction: column;
        gap: 3px;
        .price {
          font-size: 18px;
        }
        .statuses {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 6px;
          row-gap: 6px;
          column-gap: 6px;
          svg {
            height: 24px;
            width: 24px;
          }
          .ntak-icon {
            border-radius: 5px;
            background-color: var(--sixty-color);
            width: 36px;
            height: 26px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.NTAK_SUCCESS {
              svg {
                .svgFill {
                  fill: var(--green);
                }
              }
            }
            &.NTAK_PROCESSING {
              svg {
                .svgFill {
                  fill: var(--yellow);
                }
              }
            }
            &.NTAK_SUBMIT_ERROR {
              svg {
                .svgFill {
                  fill: var(--red);
                }
              }
            }
          }
        }
      }
    }
  }
}
.no-order-selected {
  padding: 24px;
  text-align: center;
}
.selected-order-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  min-width: 332px;
  max-width: 450px;
  padding: 12px;
  border-right: 2px solid var(--border-color);
  position: relative;
  .order-head-container {
    flex: 0 0 auto;
    margin-bottom: 12px;
    .order-number {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
    .icons {
      margin-top: 12px;
      display: flex;
      justify-content: space-around;
      .icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        .text {
          font-size: 14px;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .order-notes {
    margin-bottom: 12px;
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
  }
  .order-tipp {
    margin: 12px 0;
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
  }
  .order-vip {
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
    text-align: center;
    margin: 6px 0;
  }
  .delivery-cost {
    display: flex;
    justify-content: space-between;
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
  }
  .order-items-root {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .order-item-header {
      display: flex;
      font-weight: bold;
      margin-bottom: 6px;
      .amount {
        flex: 0 0 80px;
        text-align: center;
      }
      .price {
        flex: 0 0 80px;
        text-align: center;
      }
      .name {
        flex: 1 1 auto;
      }
    }
    .order-items-rows-container {
      overflow: auto;
      .item-rows {
        margin-bottom: 12px;
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid var(--border-color);
        .row {
          display: flex;
          background-color: var(--sixty-color);
          padding: 12px;
          .name {
            flex: 1 1 auto;
          }
          .count {
            flex: 0 0 60px;
            text-align: center;
          }
          .price {
            flex: 0 0 80px;
            text-align: right;
          }
          &.item-row {
            padding-bottom: 0;
          }
          &.size-row {
            padding-top: 0;
            padding-left: 16px;
            font-size: 14px;
          }
          &.notes-row {
            font-style: italic;
            font-size: 14px;
            padding-top: 0;
            padding-left: 16px;
          }
          &.price-row {
            padding: 0 12px 12px 24px;
            font-size: 14px;
          }
          &.modifier-row {
            padding: 0 12px 0 24px;
            font-size: 14px;
            font-style: italic;
            &.totalPrice-row {
              border-top: 1px solid var(--border-color);
              padding: 6px 0 0 0;
              margin: 3px 12px 0 24px;
            }
            &.take-away {
              text-transform: uppercase;
              font-style: normal;
            }
            &:last-of-type {
              padding-bottom: 12px;
            }
          }
        }
      }
    }
  }
  .vat-root {
    margin: 12px 0 12px 0;
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
  }
  .vat-group-head,
  .vat-group {
    display: flex;
    .vat {
      flex: 1 1 auto;
    }
    .amount-net,
    .amount-gross {
      flex: 0 0 90px;
      text-align: right;
    }
  }
  .vat-group-head {
    font-weight: bold;
  }
  .vat-groups {
    flex: 0 0 auto;
    .vat-group {
      display: flex;
    }
  }
  .order-payments {
    flex: 0 0 auto;
    background-color: var(--thirty-color);
    padding: 6px;
    border-radius: 5px;
    .payment {
      display: flex;
      justify-content: space-between;
    }
  }
  .order-pay-sum {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
    font-weight: bold;
    margin-top: 12px;
  }
}
