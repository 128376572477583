.create-new-category-modal {
  .modal-content {
    color: var(--text-color);
    background: var(--thirty-color);
    .create-new-category-root {
      .create-new-category-title {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 700;
        font-size: 24px;
      }
      .create-new-category-container {
        row-gap: 20px;
        column-gap: 20px;
        margin-top: 15px;
        .new-category-single-data {
          width: 400px;
          display: flex;
          flex-direction: column;
          row-gap: 5px;
          .data-name {
            font-size: 12px;
            color: var(--icon-color);
          }
          .textField-root {
            width: 100%;
            .textField-base {
              background: var(--sixty-color);
              color: var(--text-color);
              padding: 12px;
              .textField-input {
                padding: 0px;
              }
            }
          }
        }
      }
      .button-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        column-gap: 10px;
        margin-top: 30px;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7em;
          height: 2.5em;
          border-radius: 5px;
          background: var(--sixty-color);
          font-weight: 700;
          &.highlighted {
            background: var(--ten-color);
          }
          &.disabled {
            color: var(--icon-color);
          }
        }
      }
    }
  }
}
