.edit-item-modal {
  .modal-content {
    background: var(--thirty-color);
    .edit-item-root {
      .edit-item-title {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 700;
      }
      .edit-item-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;
        margin-top: 15px;
        .new-item-single-data {
          width: 400px;
          .data-name {
            font-size: 12px;
            color: var(--icon-color);
          }
          .textField-root {
            width: 100%;
            .textField-base {
              background: var(--sixty-color);
              .textField-input {
              }
            }
          }
        }
      }
      .button-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
        column-gap: 10px;
        margin-top: 30px;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 7em;
          height: 2.5em;
          border-radius: 5px;
          background: var(--sixty-color);
          font-weight: 700;
          font-size: var(--font-height-t20);
          &.highlighted {
            background: var(--ten-color);
          }
        }
      }
    }
  }
}

.edit-new-item-root-input-field-select {
  color: black;
  .textField-base {
    color: black;
  }
  .textField-label {
    font-size: 1em;
    color: black;
  }
}
