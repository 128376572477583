.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1f2023;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  flex-direction: column;
  gap: 24px;
  .logo-container {
  }
  .img-container {
    width: 250px;
    height: 250px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.okeoke-loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  svg {
    height: 100px;
    width: 100px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
    pointer-events: none;
  }
}
.fade-out {
  animation: 1s fade-out forwards;
}
