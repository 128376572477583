.device-activation-root {
  width: 100%;
  height: 100%;
  color: #fff;
  background: var(--sixty-color);
  display: flex;
  padding: 36px;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 450px;
  .top-row {
    .text {
      margin-bottom: 12px;
    }
    .logo {
      height: 60px;
      img {
        height: 100%;
      }
    }
  }
  .qr-container {
    position: absolute;
    bottom: 24px;
    right: 24px;
    .label {
      font-size: 12px;
      margin-bottom: 6px;
    }
  }
  .left-side,
  .mid {
    width: 100%;
    max-width: 410px;
    flex-shrink: 0;
  }
  .left-side {
    padding: 12px;
    min-height: 320px;
    max-width: 90%;
    flex-shrink: 0;
    .logo {
      width: 300px;
      margin: auto;
    }
  }
  .right-side {
    width: 100%;
    min-height: 40px;
  }
  .logo {
    margin-bottom: 2em;
    width: 100%;
    img {
      width: 100%;
    }
  }
  &.isMobile {
    flex-direction: column;
    justify-content: flex-start;
    .qr-container {
      display: none;
    }
    .text {
      font-size: 12px;
    }
    .left-side,
    .mid,
    .right-side {
      width: calc(100% - 12px);
    }
    .device-activation-title {
      font-size: 18px;
      padding: 12px;
    }
    .device-activation-request-code {
      padding: 12px;
    }
    .pin-container {
      padding: 12px;
    }
    .device-activation-error {
      text-align: center;
      font-size: 12px;
      width: 90%;
      max-width: 300px;
    }
  }
  &.isTablet {
    @media (orientation: landscape) {
    }
  }
  .device-activation-request-code {
    border: 2px solid;
    width: 100%;
    background: #1f2023;
  }
  .login-pin-numbers {
    max-width: 250px;
    margin: auto;
  }
  .device-activation-error {
    margin-top: 1em;
    background-color: red;
    border-radius: 5px;
    border: 1px solid;
    padding: 0.5em;
    max-width: 300px;
  }
}
.login-container {
  color: var(--text-color);
  .device-activation-screen {
    .device-activation-input-container {
      height: 4rem;
      border: 2px solid;
      background: var(--sixty-color);
    }
    .device-activation-numpad {
      .device-activation-button {
        border: 2px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        &:last-child {
          background: var(--ten-color);
        }
      }
    }
  }
}

.switch-container-root {
  position: absolute;
  bottom: 130px;
  right: 24px;
  background: var(--sixty-color);
  .switch-mode {
    display: flex;
    gap: 12px;
    row-gap: 12px;
    column-gap: 12px;
    margin: 2em 0;
    .mode {
      height: 42px;
      width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      &.selected {
        background: var(--ten-color);
      }
      &.not-selected {
        opacity: 0.2;
      }
      svg {
        height: 24px;
        width: 24px;
        .svgFill {
          fill: #fff;
        }
        .svgStroke {
          stroke: #fff;
        }
      }
    }
  }
  .mode-message-container {
    height: 100px;
    .btn {
      margin-right: 12px;
    }
    .mode-message {
      height: 3em;
    }
  }
}

.numpad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .login-button {
    border-color: var(--text-color);
  }
}

.pin-container {
  border: 2px solid #393c49;
  width: 250px;
  background: #1f2023;
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 14px;
  border-radius: 5px;
  height: 4.5rem;
  margin: 0 auto 0.5em;
}
