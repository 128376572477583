.fiscal-printer-control-panel-container {
    padding: 24px;
    .name {
        font-size: 24px;
        margin-bottom: 1em;
    }
}
.day-open-modal-content {
    .input-root {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        input {
            -webkit-tap-highlight-color: rgba(0,0,0,0);
            border: none;
            background: #fff;
            width: 170px;
            font-size: 28px;
            text-align: right;
            padding: 3px 3px 3px 12px;
            height: 46px;
        }
        .currency {
            background: #fff;
            font-size: 26px;
            color: #000;
            padding-top: 4px;
        }
    }
    .actions {
        .btn {
            background: #fff;
            color: #000;   
            &.primary {
                background: #ea7c69;
                color: #fff;   
            }
        }
    }
}