.number-row-container-root {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
    &.passive {
        .icon-btn {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }
    .icon-btn {
        transition: 0.3s opacity;
    }
    &.version2 {
        height: 3em;
        padding: 0 24px;
        margin-bottom: 1em;
    }
    .number-row {
        display: flex;
        height: 100%;
        .number-in-circle {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background: var(--sixty-color);
            border: 2px solid var(--border-color);
            color: #fff;
            font-weight: 700;
            margin-right: 6px;
            transition: all 0.3s;
            &:active {
                background: var(--ten-color);
            }
        }
    }
    .item-multiplier-number {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: 700;
        color: var(--ten-color);
        width: 60px;
        padding-right: 6px;
        height: 100%;
        font-size: 22px;
    }
}