.modal.datePicker-modal {
    .modal-bgLayout {
    }
    .modal-content {
        box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
        background: var(--thirty-color);
        color: var(--text-color);
        .rdrDateRangePickerWrapper {
            margin-top: 4em;
            .rdrDefinedRangesWrapper{
                background: unset;
                border: unset;
                .rdrStaticRanges{
                    .rdrStaticRange{
                        background:unset;
                        border: unset;
                        color: var(--text-color);
                        &.rdrStaticRangeSelected{
                            color: var(--ten-color) !important;
                        }
                    }
                }
                .rdrInputRanges{
                    .rdrInputRange{
                        .rdrInputRangeInput{
                            color:var(--text-color);
                            background: var(--sixty-color);
                            border:unset;
                        }
                    }
                }
            }
            .rdrCalendarWrapper{
                color: var(--text-color);
                background:unset;
                .rdrDateDisplayWrapper{
                    background-color: unset;
                    .rdrDateDisplay{
                        .rdrDateInput{
                            background: var(--sixty-color);
                            &.rdrDateDisplayItemActive{
                                border-color: var(--ten-color);
                            }
                        }
                    }
                }
                .rdrMonthAndYearWrapper{
                    .rdrMonthAndYearPickers{
                        .rdrMonthPicker{
                            select{                                
                                color: var(--text-color);
                            }
                        }
                        .rdrYearPicker{
                            select{                                
                                color:var(--text-color);
                            }
                        }
                    }
                }
                .rdrMonths{
                    .rdrMonth{
                        .rdrWeekDays{
                            .rdrWeekDay{
                                color: var(--text-color);
                            }
                        }
                        .rdrDays{
                            .rdrDay{
                                .rdrStartEdge{
                                    color: var(--ten-color) !important;
                                }
                                .rdrInRange{
                                    color: var(--ten-color) !important;
                                }
                                .rdrEndEdge{
                                    color: var(--ten-color) !important;
                                }
                                // &.rdrDayStartOfWeek{
                                //     background: var(--ten-color) !important;
                                // }
                                .rdrDayNumber{
                                    span{
                                        color:var(--text-color);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .date-picker-actions {
            position: absolute;
            top: 1em;
            right: 1em;
            display: flex;
            align-items: center;
            .btn {
                margin-left: 1em;
            }
        }
    }
}