.basket-throw-modal-root{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .text{
        font-size: 24px;
        color: #fff;
    }
    .button-container{
        display: flex;
        gap: 30px;
    }
}