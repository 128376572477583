.vip-modal-root {
    display: flex;
    flex-direction: column;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    background-color: var(--sixty-color);
    padding: 12px;
    border-radius: 5px;
    .header{
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
        .back-icon {
            cursor: pointer;
            opacity: 0.7;
            display: flex;
            align-items: center;
            svg {
                height: 18px;
                .svgText {
                    fill: #fff;
                }
            }
        }
        .header-text {
            opacity: 0.7;
            cursor: pointer;
        }
        .title {
            flex: 1 1 auto;
            text-align: center;
            font-size: 18px;
        }
    }
    .body {
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        .vip-info-container {
            width: 300px;
            .title {
                font-size: 18px;
            }
            .info-rows {
                padding: 12px;
                display: flex;
                gap: 12px;
row-gap: 12px;
column-gap: 12px;
                flex-direction: column;
                .info-row {
                    .label {
                        opacity: 0.7;
                    }
                    .value {
                        font-size: 18px;
                    }
                }
            }
        }
        .numpad-container {
            gap: 0;
            background-color: var(--thirty-color);
            padding-bottom: 12px;
            border-radius: 5px;
        }
    }
    .footer {
        display: flex;
        justify-content: space-around;
        .btn {
            svg {
                height: 24px;
                .svgFill {
                    fill: var(--red);
                }
                .svgStroke {
                    stroke: var(--red);
                }
            }
            &.btn-warning {
                gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
                border: 2px solid var(--red);
                background-color: var(--thirty-color);
                color: #fff;
            }
        }
    }
}