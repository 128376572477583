.svgBg{
    fill: grey;
}
.svgText{
    fill: var(--text-color);
}
.svgFill {
    fill: var(--text-color)
}
.svgStroke {
    stroke: var(--text-color)
}