.input-field-multi-select-main-container.textField-root {
  position: relative;
  min-width: 165px;
  .textField-base {
    cursor: pointer;
  }
  .title {
    position: absolute;
    font-family: "Barlow";
    top: -23px;
    left: 1px;
    height: 23px;
  }
  .select-box {
    display: flex;
    align-items: center;
    height: 2em;
    background-color: #ededed;
    border-radius: 5px;
    padding: 0px 12px;
    font-family: "Barlow";
    line-height: 22px;
    font-weight: 500;
    border-style: none;
    border-color: var(--text-color);
    &.show-error {
      border-style: solid;
      border-color: #b8401a;
      border-width: 0.8px;
    }
  }
  .error-row {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    line-height: 22px;
    font-family: "Barlow";
    color: #ec5252;
    white-space: nowrap;
  }
  .select-panel {
    display: flex;
    align-items: center;
    height: 2em;
    padding: 0.5em 1em;
    cursor: pointer;
    border-radius: 5px;
    // background-color: #ededed;
    list-style: none;
    font-weight: 500;
    font-family: "Barlow";
    user-select: none;
    background-color: var(--inputField-background-color);
    color: var(--text-color);
  }

  /*summary:after {
    content: "";
    display: inline-block;
    position: absolute;
    right: 12px;
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 1px solid currentColor;
    border-left: 1px solid currentColor;
    border-bottom-left-radius: 2px;
    transform: rotate(-45deg) translate(10%, -10%);
    transform-origin: center center;
    transition: transform ease-in-out 100ms;
  }*/

  /*details[open] summary:after {
    position: absolute;
    right: 12px;
    transform: rotate(135deg) translate(0%, 0%);
  }*/

  .list {
    width: 95%;
    background: #f5f5f5;
    position: absolute;
    // padding: 0px 0px 23px;
    margin: 0 2.5%;
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 2;
    background-color: var(--inputField-background-color);
    color: var(--text-color);
  }

  label {
    width: 100%;
    display: block;
    cursor: pointer;
  }
}

.okeoke-input-field-multi-select-selection-table {
  .list-element {
    display: flex;
    padding: 0 1em 0 3em;
    height: 2em;
    align-items: center;
    user-select: none;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 0.5em;
    .label {
      white-space: nowrap;
      font-weight: 500;
      font-family: "Barlow";
      cursor: pointer;
    }
    .svg-container {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        height: 1em;
      }
      .svgText {
        stroke: var(--main-text-color);
      }
    }
    &:hover {
      background-color: var(--inputField-highlight);
    }
    &.selected {
      background-color: var(--inputField-highlight);
    }
  }
}

.InputFieldMultiSelect-modal {
  &.modal {
    .modal-content {
      position: absolute;
      box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.25);
      overflow: auto;
    }
  }
}
.multi-select-pos-design-InputFieldMultiSelect-modal {
  .modal-content {
      max-height: 40vh;
    background: var(--thirty-color);
    .okeoke-input-field-multi-select-selection-table{
        .list-element {
            background: var(--sixty-color);
            color: var(--text-color);
            &.selected{
                background: var(--ten-color);
            }
        }
    }
  }
}
