.table-modal-content {
    .title {
        font-size: 24px;
        margin-bottom: 12px;
        font-weight: bold;
        text-align: center;
    }
    .table-cards-container {
        display: flex;
        max-width: 500px;
        max-height: 800px;
        justify-content: center;
        flex-wrap: wrap;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        overflow: auto;
        .table-card {
            border: 2px solid var(--border-color);
            padding: 12px;
            border-radius: 5px;
            background-color: var(--sixty-color);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
            width: 120px;
            &.selected {
                border-color: var(--ten-color);
            }
            .table-name {
                font-size: 18px;
            }
        }
    }
}