.options-modal {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    p {
        font-weight: bold;
    }
    .modal-body {
        display: flex;
        overflow: auto;
    }
    .option-list{
        display: flex;
        flex-direction: column;
        row-gap: 0.3em;
        .title{
            font-weight: 700;
        }
        .option{
            display: flex;
            padding: 0px 0.5em;
            justify-content: space-between;
            row-gap: 0.3em;
            align-items: center;
            gap: 50px;
            .radio-circle{
                width: 1em;
                height: 1em;
                border-radius: 50%;
                border: 2px var(--border-color) solid;
                background-color: var(--sixty-color);
                &.active{
                    border:unset;
                    background-color: var(--ten-color);
                }
            }
            .action-buttons{
                display: flex;
                gap: 15px;
                width: 80px;
            }
        }
    }
    .item-columns {
        display: flex;
        flex-wrap: wrap;
        .item-column-btn {
            background: var(--sixty-color);
            padding: 3px 13px;
            margin: 6px;
            border-radius: 5px;
            border: 1px solid var(--border-color);
            &.selected {
                color: #fff;
                background: var(--ten-color);
            }
        }
    }
}