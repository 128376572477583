.tutorial-layer {
    .center-root {
        .left-side {
            height: 500px;
            width: 300px;
            margin-right: 24px;
            margin-left: auto;
            .title, .message {
                background-color: var(--thirty-color);
                border: 2px solid var(--ten-color);
            }
            .title {
                font-size: 24px;
                padding: 12px;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                border-bottom: none;
            }
            .message {
                padding: 0 12px 12px;
                border-bottom-right-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top: none;
            }
        }
        .login-container {
            margin-right: 0px;
            margin-top: calc(-1 * var(--header-height));
        }
    }
}