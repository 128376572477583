.common-printer-control-panel-container {
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .title {
        font-size: 24px;
        font-weight: bold;
    }
    .sub-title {
        font-size: 20px;
        margin: 24px 0 12px;
    }
    .name {
        font-size: 18px;
        flex-shrink: 0;
    }
    .box-container {
        margin-top: 12px;
        //flex: 1 1 auto;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        //align-items: center;
        align-content: flex-start;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        .device-data-box {
            padding: 12px;
            background-color: var(--thirty-color);
            border-radius: 5px;
            border: 2px solid var(--border-color);
            transition: opacity 0.3s;
            transition: all 0.3s;
            &.selected {
                border-color: var(--ten-color);
            }
            .title {
                font-weight: normal;
                font-size: 18px;
                margin-bottom: 12px;
            }
            .select-printer {
                margin-top: 12px;
                cursor: pointer;
                font-size: 12px;
                text-decoration: underline;
            }
        }
    }
}
.printer-modal {
    display: flex;
    flex-direction: column;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: 676px;
    .settings-fields {
        width: 700px;
        max-width: 100%;
        height: 400px;
        max-height: 55vh;
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        overflow: auto;
        align-items: center;
        align-content: flex-start;
        .input-field-select-main-container {
            min-width: 200px;
        }
    }
    .choose {
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .printer-cards-container {
        width: 100%;
        height: 100%;
    }
    .textField-root {
        background-color: var(--sixty-color);
    }
    .modal-title {
        font-size: 24px;
        flex-shrink: 0;
        margin-bottom: 6px;
        color: #fff;
    }
    .modal-body {
        flex: 1 1 auto;
        overflow: auto;
        .textField-root {
            margin-bottom: 12px;
        }
        .row {
            display: flex;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
        }
        .box {
            .title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 6px;
            }
            .sub-title {
                margin-bottom: 6px;
            }
        }
    }
    .buttons-container {
        flex-shrink: 0;
        margin-top: 12px;
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        align-items: center;
        justify-content: center;
    }
}