.modal.settings-wizard-modal {
    .modal-content {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        padding: 0;
    }
}
.settings-wizard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mt-1 {
        margin-top: 12px;
    }
    .header {
        display: flex;
        height: 82px;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        align-items: center;
        position: relative;
        justify-content: space-between;
        flex-shrink: 0;
        padding: 12px;
        background-color: rgba(0, 0, 0, 0.2);
        .line {
            position: absolute;
            border-top: 2px solid var(--border-color);
            width: calc(100% - 24px);
            z-index: 0;
        }
        .step {
            z-index: 1;
            width: 60px;
            height: 60px;
            flex-shrink: 0;
            border-radius: 100%;
            border: 2px solid var(--border-color);
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            position: relative;
            background-color: var(--thirty-color);
            transition: all 0.3s;
            overflow: hidden;
            cursor: not-allowed;
            &.done {
                cursor: pointer;
                border-color: var(--green);
                .number, .step-name {
                    opacity: 1;
                    color: var(--green);
                }
                .inner-bg {
                    background-color: var(--green);
                    opacity: 0.3;
                }
            }
            &.active {
                border-color: var(--ten-color); 
                .number, .step-name {
                    opacity: 1;
                    
                }
                .inner-bg {
                    background-color: var(--ten-color);
                    opacity: 0.3;
                }
            }
            .inner-bg {
                background-color: var(--thirty-color);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 100%;
                z-index: 1;
            }
            .number {
                z-index: 2;
                opacity: 0.5;
                font-size: 18px;
            }
            .step-name {
                z-index: 2;
                opacity: 0.5;
            }
        }
    }
    .wizard-step {
        padding: 12px 12px 0;
        overflow: auto;
        margin-bottom: auto;
        flex: 1 1 auto;
        .title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 12px;
            text-align: center;
        }
    }
    .actions {
        display: flex;
        justify-content: space-around;
        padding: 12px 150px;
        flex-shrink: 0;
        background-color: rgba(0, 0, 0, 0.2);
        .btn {
            &.disabled {
                opacity: 0.7;
                cursor: not-allowed;
            }
        }
    }
    .service-fee-settings {
        height: 100%;
        .outer-container {
            height: calc(100% - 40px);
            display: flex;
            .settings-container {
                width: 50%;
                flex-shrink: 0;
                .choose {
                    display: flex;
                    gap: 12px;
row-gap: 12px;
column-gap: 12px;
                    margin-bottom: 12px;
                }
            }
        }
    }
    .help-text-container {
        flex: 1 1 auto;
        padding: 0 12px;
    }
    .pos-printer-settings {
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        .outer-container {
            display: flex;
            height: calc(100% - 40px);
            .settings-container {
                width: 450px;
                flex-shrink: 0;
            }
        }
        .choose {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
            margin-bottom: 24px;
            .btn {
                width: 130px;
                height: 70px;
            }
        }
        .app-params {
            margin: 12px 0;
        }
        .settings-fields {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
            .textField-root {
                min-width: 150px;
            }
            .textField-root.available-printers {
                min-width: 100%;
            }
        }
    }
    .fiscal-printer-settings {
        display: flex;
        flex-direction: column;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        height: 100%;
        overflow: hidden;
        .fields-container {
            display: flex;
            height: calc(100% - 46px);
            .help-text-box {
                flex: 1 1 auto;
                padding: 12px;
            }
        }
        .printer-settings-fields {
            min-width: 50%;
            overflow: auto;
            .box {
                margin-bottom: 12px;
                .row {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
row-gap: 12px;
column-gap: 12px;
                    .textField-root {
                        min-width: 220px;
                    }
                }
            }
            .vat-box {
                .row {
                    .textField-root {
                        min-width: 50px;
                    }
                }
            }
        }
    }
    .tipp-type-cards {
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        justify-content: center;
        .tipp-type-card {
            border: 2px solid var(--border-color);
            border-radius: 10px;
            padding: 12px;
            height: 300px;
            width: 250px;
            overflow: hidden;
            transition: all 0.3s;
            cursor: pointer;
            &.selected {
                border-color: var(--ten-color);
            }
        }
    }
    .ntak-outer-container {
        height: calc(100% - 40px);
        display: flex;
        .ntak-settings-container {
            width: 50%;
            .ntak-options-container {
                .btn {
                    width: 110px;
                    height: 70px;
                }
            }
        }
    }
    .ntak-options-container {
        display: flex;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        margin-bottom: 12px;
    }
    .ntak-provider {
        .input-fields {
            display: flex;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
        }
        .uploads-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
            .certificate-container {
                width: 150px;
                border: 2px solid var(--border-color);
                .icon {
                    height: 20px;
                    width: 20px;
                }
                .text {
                    display: flex;
                    text-align: center;
                }
            }
            .file-upload-container {
                .upload {
                    width: 150px;
                    label {
                        flex-direction: column;
                    }
                    svg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}
.printer-private-container {
    display: flex;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    .private {
        padding: 6px 12px;
        border: 2px solid var(--border-color);
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        .inner-bg {
            opacity: 0.26;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            pointer-events: none;
          }
          &.selected {
            border-color: var(--ten-color);
            .inner-bg {
              background: var(--ten-color);
            }
          }
    }
}
.printer-cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    overflow: auto;
    height: calc(100% - 150px);
    padding-bottom: 12px;
    align-content: flex-start;
    .printer-card {
        padding: 12px;
        border-radius: 5px;
        border: 2px solid var(--border-color);
        transition: all 0.3s;
        cursor: pointer;
        &.selected {
            border-color: var(--ten-color);
        }
        .name {
            font-weight: bold;
            font-size: 18px;
        }
        .path, .type {
            font-size: 14px;
        }
    }
}