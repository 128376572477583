.pagination-root {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 342px);
    gap: 10px;
    padding: 4px 12px 0 12px;
    overflow: auto;
    flex-shrink: 0;
    position: fixed;
    bottom: 0;
    left: 42px;
    background-color: var(--header-background);
    height: 43px;
    
    &.sell-right-to-left {
      left: auto;
      right: 0;
    }
  .pagination-element {
    padding: 6px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: 1px solid; border-color: var(--border-color);
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    background-color: var(--sixty-color);
    .inner-bg {
      opacity: 0.26;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
    }
    &.selected {
      border-color: var(--ten-color);
      .inner-bg {
        background: var(--ten-color);
      }
      color: var(--ten-color);
    }
  }
}
