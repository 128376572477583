.not-available-root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
    min-height: 100px;
    background-color: var(--sixty-color);
    border-radius: 10px;
}