.modifier-btn {
    display: flex;
    border-radius: 5px;
    padding: 0;
    background-color: var(--thirty-color);
    border: 1px solid var(--border-color);
    position: relative;
    &.selected {
        border-color: var(--ten-color);
        .inner-bg {
            background-color: var(--ten-color);
        }
    }
    .inner-bg {
        opacity: 0.26;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    .visibility-container {
        position: absolute;
        width: 38px;
        height: 38px;
        z-index: 0;
        opacity: 0.2;
        pointer-events: none;
        right: 6px;
        top: 6px;
        svg {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    .modifier-btn-text {
        position: relative;
        z-index: 2;
    }
    .modifier-btn-bin-container {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: 0 8px;
        svg {
            height: 20px;
        }
        &.passive {
            svg {
                opacity: 0.1;
                .svgFill {
                    fill: #fff;
                }
            }
        }
    }
    .modifier-btn-label {
        display: flex;
        padding: 6px 6px 0 0;
        gap: 3px;
        .count {
            width: 35px;
        }
    }
    .modifier-btn-price {
        font-size: 12px;
        padding-bottom: 6px;
        margin-top: 3px;
        color: var(--ten-color);
    }
}