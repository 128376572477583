.item-editor-container-root {
  max-width: 300px;
  gap: 12px;
row-gap: 12px;
column-gap: 12px;
  .header {
    display: flex;
    align-items: center;
    .text {
      font-size: 24px;      
    }
  }
  .editor-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    margin-top: 6px;
    .enabled-container {
      display: flex;
      gap: 12px;
row-gap: 12px;
column-gap: 12px;
      align-items: center;
      .switch-btn {
        padding: 6px 12px;
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.09);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s;
        &.active {
          background-color: var(--ten-color);
          color: #fff;
          transition: all 0.3s;
        }
        &.inactive {
          opacity: 0.7;
          transition: all 0.3s;
        }
      }
    }
    .inputfield-containers {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
row-gap: 12px;
column-gap: 12px;
      .ntak-select {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        .textField-root {
          width: 100%;
        }
      }
      .item-editor-inputfield {
        display: flex;
        flex-direction: column;
        width: 100%;
        .upper-label {
          font-size: 12px;
          color: var(--icon-color);
          margin-bottom: 3px;
        }
        .textField-root {
          width: 100%;
          background: var(--thirty-color);
          .textField-base {
            color: var(--text-color);
            padding: 6px;
            .textField-input {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

.modal.sizes-modal {
  .modal-content {
    //max-width: 95%;
    //max-height: 95%;
    //width: 95%;
  }
}

.sizes-editor-root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
row-gap: 12px;
column-gap: 12px;
  .title {
    font-size: 24px;
    text-align: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    max-height: 400px;
    .sizes-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
row-gap: 12px;
column-gap: 12px;
      overflow: auto;
      .size-row {
        padding: 12px;
        background: var(--thirty-color);
        display: flex;
        align-items: center;
        border-radius: 5px;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        flex-wrap: wrap;
        .col {
          display: flex;
          flex-direction: column;
          gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
          height: 100%;
        }
        .row {
          display: flex;
          gap: 12px;
row-gap: 12px;
column-gap: 12px;
          position: relative;
          &.open {
            padding-top: 20px;
            transition: all 0.3s;
            height: 80px;
          }
          &.closed {
            padding-top: 14px;
            height: 14px;
            overflow: hidden;
            transition: all 0.3s;
            .toggler {
              svg {
                transform: rotate(180deg);
                transition: all 0.3s;
              }
            }
          }
          .toggler {
            cursor: pointer;
            position: absolute;
            top: 3px;
            width: 100%;
            height: 16px;
            display: flex;
            justify-content: center;
            svg {
              height: 18px;
              transition: all 0.3s;
            }
          }
        }
        
        .ntak-select, .unit-container {
          display: flex;
          gap: 12px;
row-gap: 12px;
column-gap: 12px;
          .textField-root {
            min-width: 150px;
          }
        }
        .unit-container {
          .unit-field {
            min-width: 120px;
            width: 120px;
          }
        }
        &.disabled {
          opacity: 0.5;
        }
        .size-name {
          flex-shrink: 0;
          width: 120px;
        }
        .size-price {
          flex-shrink: 0;
          width: 120px;
        }
        .size-function-buttons {
          display: flex;
          height: 100%;
          align-items: center;
          justify-content: flex-end;
          gap: 12px;
row-gap: 12px;
column-gap: 12px;
          flex-grow: 1;
          flex-shrink: 0;
          min-width: 55px;
          .size-default-radio {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            background: var(--sixty-color);
            border-radius: 100%;
            &.active {
              background: var(--ten-color);
            }
          }
          .size-enable, .size-availability, .size-visibility {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            svg {
              .svgText {
                fill: var(--icon-color);
              }
            }
          }
        }
        .size-availability {
          display: flex;
          width:100%;
          justify-content:center;
          gap: 30px;
          .size-available {
            width: 20px;
            svg {
              .svgText {
                fill: var(--icon-color);
              }
            }
          }
          .size-visible {
            width: 20px;
            svg {
              .svgText {
                fill: var(--icon-color);
              }
            }
          }
        }
      }
    }
    .new-size {
      width: 100%;
      background: var(--thirty-color);
      padding: 12px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      font-weight: 400;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;  
  }
}
.size-modal-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
row-gap: 12px;
column-gap: 12px;
  background-color: var(--thirty-color);
  padding: 12px;
  border-radius: 5px;
  .size-modal-title {
    font-weight: 700;
    font-size: 20px;
  }
  .size-modal-textfield {
    color: var(--icon-color);
    font-weight: 400;
    display: flex;
    flex-direction: column;
    row-gap: 0.2em;
    .textField-root {
      width: 200px;
      .textField-base {
        background: var(--sixty-color);
        color: var(--text-color);
        font-weight: 400;
        font-size: var(--font-height-t25);
        .textField-input {
          padding: 0.5em 0.75em 0.5em;
        }
      }
    }
  }
  .ntak-select {
    display: flex;
    flex-direction: column;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    .textField-root {
      background-color: var(--thirty-color);
      width: 200px;
    }
  }
  .button-container {
    display: flex;
    gap: 48px;
  }
}
