.modal.welcome-demo-modal {
    .content {
        max-width: 500px;
        max-height: 80%;
    }
    .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;    
        margin-bottom: 12px;
        height: 22px;
    }
    .body {
        .text {
            margin-bottom: 12px;
            text-align: center;
        }
    }
    .actions {
        text-align: center;
    }
}