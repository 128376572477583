.create-categ-root {
    .text-box {
        position: absolute;
        top: 24px;
        right: 0px;
        width: 300px;
        padding: 12px;
        border-radius: 5px;
    }
}
.set-category-root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 12px;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    .row {
        display: flex;
        align-items: flex-start;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        justify-content: flex-end;
        padding-right: 12px;
        min-height: 50px;
        text-align: right;
        svg {
            transform: rotate(180deg);
            height: 24px;
        }
    }
    .category-head {
        height: 150px;
    }
    .action {
        .btn {
            margin: 0 12px;
        }
    }
}
.create-item-root {
    .text-box {
        padding: 12px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);  
        &.message {
            position: absolute;
            top: 12px;
            right: 12px;
            max-width: 300px;
        }
        &.action {
           position: absolute;
           top: 250px;
           right: 20px;
           border: none;
        }
    }
}