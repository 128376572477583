.order-summary-container {
  width: 100%;
  height: calc(100% - 186px);
  transition: all 0.3s ease-in-out;
  &.open {
    height: calc(100% - 328px);
  }
  .sell-not-allowed {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    background: rgba(0, 0, 0, 0.8);
    z-index: 20;
    padding: 24px;
    color: #fff;
    input {
      background-color: #fff;
    }
    &.day-open {
      height: 100%;
      .text, input, .btn {
        display: none;
      }
    }
  }
  .order-summary-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
    border-radius: 5px;
    padding: 12px 0;
    height: 100%;
    .order-summary-items-container {
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      .order-summary-item-quantity {
        // width: 58px;
        text-align: right;
      }
      .order-summary-item-price {
        // width: 52px;
        margin-right: 20px;
        text-align: right;
      }
      .order-summary-item-name {
        // width: calc(100% - 110px);
        flex-grow: 1;
        text-align: left;
      }
      .order-summary-items-header {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        // margin-bottom: 25px;
        background: var(--thirty-color);
        position: absolute;
        width: 100%;
        height: 25px;
        z-index: 2;
      }
      .order-summary-items-body {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        overflow: auto;
        .empty-basket {
          padding: 36px 12px;
          text-align: center;
        }
        .order-summary-item-quantity,
        .order-summary-item-price,
        .order-summary-item-name,
        .order-summary-item {
          display: flex;
          margin-bottom: 6px;
          line-height: 1;
        }
      }
    }
  }
}

.order-summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  height: 60px;
  .order-summary-title {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
    height: 100%;
    font-size: 20px;
    padding-top: 6px;
    flex: 1 1 auto;
    flex-shrink: 0;
    overflow: hidden;
    max-width: 252px;
  }
  .drop-basket-container {
    height: 100%;
    padding: 6px 0 0;
    .icon-btn {
      width: 24px;
      height: 24px;
    }
  }
}
.order-summary-header-btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
  //justify-content: center;
  //padding: 6px;
  margin: 1px 4px;
  height: 32px;
  min-height: 32px;
  filter: var(--orderItemShadow);
  .action-button {
    display: flex;
    justify-content:center;
    align-items: center;
    width: 32px;
    height: 32px;
    //border: 2px solid var(--border-color);
    border-radius: 5px;
    background: var(--sixty-color);
    cursor: pointer;
    position: relative;
    transition: all 0.3s;
    &.selected {
      background-color: rgba(63, 154, 183, 0.26);
      border: 1px solid; border-color: var(--ten-color);
      .inner-bg {
        background-color: var(--ten-color);
        opacity: 0.26;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      svg {
        .svgText {
          fill: var(--ten-color);
        }
      }
    }
    svg{
      width:18px;
      height:18px;
      .svgText{
        fill: var(--ten-color);
      }
      .svgFill{
        fill: var(--ten-color);
      }
      .svgStroke{
        stroke: var(--ten-color);
      }
    }
  }
}

.order-summary-total {
  padding-top: 6px;
  display: flex;
  border-top-style: solid;
  border-top-width: 1px;
}

.order-summary-total-name {
}

.order-summary-total-price {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.order-identifier-field {
  background-color: var(--sixty-color);
  width: 100%;
  &.textField-root {
    min-height: unset;
  }
}

.identifier-presets {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  max-width: 300px;
  margin: 12px 0;
}

.order-note-modal-field {
  width: 500px;
  height: 300px;
  background-color: var(--sixty-color);
  textarea {
   height: 296px;
   padding: 12px;
  }
}