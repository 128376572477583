.okeoke-input-switch {
    user-select: none;
    display: flex;
    align-items: center;
    &.disabled {
        .checkbox-container {
            .checkbox {
                opacity: 0.6;
            }
        }
        .switch-container {
            .slider {
                opacity: 0.6;
            }
        }
    }
    &.checkbox-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .label {
            width: calc(100% - 1.7em);
            overflow: hidden;
        }
        .checkbox {
            width: 1.2em;
            height: 1.2em;
            padding: 0.1em;
            margin-left: 0.5em;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 0.8em;
                height: 0.8em;
                .svgStroke {
                    stroke: #fff;
                }
            }
        }
    }
    .switch-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
        cursor: pointer;
        .label {
            width: calc(100% - 1.7em);
            overflow: hidden;
        }
        .slider {
            min-width: 2.5em;
            min-height: 1.2em;
            border-radius: 50px;
            position: relative;
            border: 2px solid var(--border-color);
            &.switch-on {
                transition: 0.2s;
                background-color: var(--text-color);
                box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
            }
            &.switch-off {
                transition: 0.2s;
                background-color: rgba(0, 0, 0, 0.05);
            }
            .thumb {
                width: 0.85em;
                height: 0.85em;
                position: absolute;
                left: 2px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 100%;
                transition: 0.4s;
                &.thumb-on {
                    background: var(--sixty-color);
                    left: calc(2.5em - 0.9em - 4px);
                    transition: all 0.4s;
                }
                &.thumb-off {
                    background: var(--border-color);
                    left: 2px;
                    right: auto;
                    transition: all 0.4s;
                }
            }
        }
    }
}
.dark-mode {
    .okeoke-input-switch {
        .switch-container {
            .slider {
                .thumb {
                    &.thumb-off {
                        background: rgba(255, 255, 255, 0.3)
                    }
                }
            }
        }
    }
}