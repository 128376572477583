.transaction-header-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left-side {
    display: flex;
    height: 100%;
    align-items: flex-end;
    font-weight: 700;
    padding-bottom: 30px;
    .svg-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      background: var(--ten-color);
      border-radius: 5px;
      box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 50%);
      svg {
        width: 75%;
        height: 75%;
        .svgText {
          fill: white;
        }
      }
    }
    .text {
      padding-left: 30px;
      padding-bottom: 8px;
    }
  }
  .right-side {
    padding-top: 25px;
    padding-right: 40px;
    .time-range-container {
      padding: 15px 10px;
      background: var(--thirty-color);
      display: flex;
      align-items: center;
      column-gap: 22px;
      font-weight: 700;
      border-radius: 5px;
    }
  }
}
