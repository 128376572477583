.tutorial-layer {
    .sell-not-allowed {
        background: transparent;
    }
    .open-day-help-window {
        background-color: var(--thirty-color);
        padding: 12px;
        border-radius: 5px;
        .title {
            font-size: 22px;
            margin-bottom: 6px;
        }
    }
}