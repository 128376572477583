.device-login-main {
  width: 100%;
  height: 100%;
  background-color: #1f2023;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  .left-side,
  .right-side {
    width: 50%;
    height: 100%;
    overflow: auto;
    position: relative;
    background-color: var(--sixty-color);
    .flexible-top-padding {
      display: flex;
      flex: 1 1 10%;
      max-height: 10%;
    }
  }
  .left-side {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    .text-box {
      position: absolute;
      bottom: 10%;
      right: 50%;
      transform: translateX(50%);
      width: 100%;
      padding: 30px;
      max-width: 600px;
      .text {
        opacity: 0.85;
        margin-bottom: 12px;
      }
      .title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 12px;
      }
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
    .text-box {
      width: 100%;
      padding: 30px;
      max-width: 600px;
      .text {
        opacity: 0.85;
        margin-bottom: 12px;
      }
      .title {
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 12px;
      }
    }
  }
  .refresh-svg {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 999;
    border: 2px solid #393c49;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    svg {
      height: 24px;
      width: 24px;
      .svgFill {
        fill: #fff;
      }
    }
  }
  .device-login-welcome {
    // min-width: 410px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .logo {
      img {
        display: block;
        margin: auto;
        height: 69px;
      }
      margin-bottom: 1em;
    }
    .welcome-message2 {
      text-align: center;
      margin-bottom: 24px;
    }
  }
  .device-login-reg-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    &.step3 {
      input {
        margin-bottom: 12px;
      }
    }

    // input {
    //   padding: 12px;
    //   margin: 6px 0px 0px;
    //   background-color: rgba(0, 0, 0, 0.3);
    //   border: none;
    //   border-radius: 5px;
    //   color: #2e9bd9;
    //   width: 300px;
    //   border: 1px solid transparent;
    //   &::placeholder {
    //     color: #2e9bd9;
    //   }
    //   &.error {
    //     border: 1px solid red;
    //   }
    // }
    .welcome-message2 {
      margin: 12px 0px 24px;
      padding: 0 24px;
    }
    .welcome-message3 {
      width: 300px;
      text-align: center;
      font-size: 14px;
      font-weight: 200;
      margin: 12px 0 12px;
    }
    .cancel-progress {
      font-size: 12px;
      width: 200px;
      text-align: center;
      margin-top: 18px;
      .btn {
        margin-top: 12px;
        font-size: 14px;
        height: 2em;
      }
    }
    .company-data-row {
      display: flex;
      width: 300px;
      font-weight: 200;
      gap: 6px;
      row-gap: 6px;
      column-gap: 6px;
    }
    .terms-and-conditions-container {
      margin: 0.75em 0px;
      display: flex;
      flex-direction: column;
      width: 300px;
      .row {
        display: flex;
        margin: 0.25em 0px;
        background-color: rgba(0, 0, 0, 0.3);
        font-weight: 500;
        border-radius: 8px;
        padding: 10px;
        &.active {
          background-color: #2e9bd9;
        }
        svg {
          display: flex;
          height: 1.6em;
          width: 1.6em;
          min-width: 1.6em;
          padding: 0.3em;
          align-self: center;
          border: 2px solid white;
          border-radius: 8px;
        }
        .text {
          display: flex;
          font-size: 18px;
          padding-left: 1em;
          align-items: center;
        }
      }
      a {
        color: var(--text-color);
        margin: 0px 0px 0.5em;
      }
    }
    .data-fields {
      min-height: 100px;
    }
    .loading {
      margin-top: 1em;
    }
  }
  .welcome-message {
    font-size: 26px;
    text-align: center;
    margin-bottom: 12px;
    &.highlighted {
      text-transform: uppercase;
      font-weight: bold;
      color: #2e9bd9;
    }
  }
  .btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .btn {
      min-width: 300px;
      margin: 12px;
      background-color: rgba(0, 0, 0, 0.3);
      color: #2e9bd9;
      font-weight: bold;
      opacity: 1;
      transition: all 0.3s;
      &.highlighted {
        background: #2e9bd9;
        color: #1f2023;
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.pin-fields {
  display: flex;
  flex-direction: column;
  gap: 12px;
  row-gap: 12px;
  column-gap: 12px;
}

.device-activation-screen {
  width: 300px;
  margin: auto;
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  gap: 14px;

  .device-activation-input-container {
    width: 100%;
    margin: 0;
    border-radius: 5px;
    border: 1px solid;

    .device-activation-input {
      padding: 1rem;
      font-size: 2rem;
      min-height: 50px;
    }
  }

  .device-activation-numpad {
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 14px;
    padding: 0rem;
  }

  .device-activation-button {
    height: 50px;
    padding: 0rem;
    border-radius: 5px;
    border: 1px solid;
    font-size: 2rem;
    padding: 1rem;
  }
}

.device-activation-title {
  margin-bottom: 14px;
  background-color: #c4c4c4;
  border-radius: 5px;
  border: 1px solid;
  padding: 1rem;
  font-size: 2rem;
}

.device-activation-request-code {
  margin-bottom: 14px;
  background-color: #c4c4c4;
  border-radius: 5px;
  border: 1px solid;
  padding: 1rem;
  font-size: 2rem;
  overflow: auto;
}
.brand-settings-title {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: bold;
}
.device-login-brand-settings {
  display: flex;
  gap: 12px;
  row-gap: 12px;
  column-gap: 12px;
  .brand-button,
  .device-button {
    transition: all 0.3s;
    background-color: transparent;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    &.selected {
      background-color: #2e9bd9;
    }
  }
  .brand-list,
  .device-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    row-gap: 12px;
    column-gap: 12px;
    max-height: 500px;
    overflow: auto;
    background-color: var(--sixty-color);
    padding: 12px;
    border-radius: 5px;
  }
  .device-list {
    width: 200px;
  }
  .selected-device {
    width: 300px;
    background-color: var(--sixty-color);
    padding: 12px;
    border-radius: 5px;
    .device-name {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .btn {
      background-color: #2e9bd9;
      color: #fff;
    }
  }
  &.isMobile {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    .brand-list,
    .device-list {
      width: calc(100% - 24px);
      max-height: 200px;
    }
    .selected-device {
      flex: 1 1 auto;
    }
  }
}
.firstLogin-modal {
  .title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .message {
    text-align: center;
  }
  p {
    font-size: 16px;
    &.pin {
      font-size: 20px;
    }
  }
  .btn-container {
    text-align: center;
  }
}
