.sell-presentation-root {
    margin: 0 12px auto;
    position: relative;
    height: calc(100% - 66px);
    text-align: right;
    svg {
        height: 24px;
        transform: rotate(180deg);
    }
    .order-head {
        height: 68px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
    }
    .order-actions {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
    }
    .order-items {
        height: calc(100% - 230px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        .row {
            gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .no-items {
            display: flex;
            flex-direction: column;
            gap: 12px;
row-gap: 12px;
column-gap: 12px;
            align-items: flex-end;
            .btn {
                margin: 6px 12px;
            }
        }
    }
    .order-payment {
        position: absolute;
        bottom: 40px;
        align-items: center;
        justify-content: flex-end;
        right: 0px;
        display: flex;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
        height: 100px;
    }
}
.header-presentation-root {
    .text-box {
        padding: 12px;
        border-radius: 5px;
    }
    .menu-toggler-box {
        position: absolute;
        max-width: 200px;
        top: 12px;
        left: 12px;
    }
    .others-box {
        position: absolute;
        right: 12px;
        top: 12px;
        max-width: 180px;
    }
    .instruction-box {
        position: absolute;
        bottom: 200px;
        left: 12px;
        max-width: 330px;
        .btn {
            margin-left: 16px;
            margin-bottom: 12px;
        }
        .text {
            margin-bottom: 16px;
        }
    }
}
.left-side-presentation-root {
    .text-box {
        padding: 12px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        position: absolute;
        top: 45px;
        left: 0px;
        svg {
            height: 24px;
        }
    }
}
.sell-item-presentation-root {
    .text-box {
        padding: 12px;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
        margin-left: 100px;
    }
}
.payment-presentation-root {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    .text-box {
        padding: 12px;
        border-radius: 5px;
    }
}