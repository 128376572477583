.payment-numpad-container {
  width: 11em;
  .payment-numpad-header {
    display: none;
    @media (min-height: 595px) {
      display: block;
    }
  }
  .payment-numpad-body {
    height: calc(100% - 55px);
    padding: 7px;
    .payment-numpad-input {
      margin-bottom: 14px;
      border: 2px solid red;
      border-radius: 5px;;
      padding: 14px;
      font-weight: bold;
      text-align: center;
    }
    .payment-numpad-action-btns-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .payment-numpad-numbers-container {
      display: flex;
      .payment-numpad-numbers {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
        margin-right: 10px;
      }
      .payment-numpad-action-btns-col {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}

.payment-numpad-root {
  background: var(--thirty-color);
  padding: 24px;
  border-radius: 5px;
  .payment-numpad-header {
    font-size: 24px;
    .header-title {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
    }
  }
  .current-number{
    font-size: 24px;
    padding: 35px 45px;
    position: relative;
    width: 276px;
    margin: auto;
    text-align: right;
    svg {
      height: 30px;
      position: absolute;
      right: 0px;
      top: 32px;
    }
  }
  .payment-numpad-body {
    display: flex;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    flex-wrap: wrap;
    justify-content: center;
    width: 276px;
    margin: auto;
    .numpad-btn {
      display: flex;
      height: 60px;
      width: 60px;
      background: var(--sixty-color);
      border: 2px solid var(--border-color);
      border-radius: 10px;
      font-weight: 700;
      // padding-bottom: 100% ;
      position: relative;
      transition: all 0.2s;
      &:active {
        background: var(--ten-color);
      }
      .number{
        position:absolute;
        display: flex;
        justify-content:center;
        align-items: center;
        height:100%;
        width:100%;
        font-size: 32px;
      }
      &.special{
        background: var(--ten-color);
      }
      svg{
        width: 24px;
        height: 24px;
        .svgText{
          fill: var(--text-color);
        }
      }
    }
  }
}
