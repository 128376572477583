.basket-modal-root{
    max-width: 500px;
    max-height: 500px;
    display:flex;
    flex-direction: column;
    gap: 30px;
    background-color: var(--sixty-color);
    padding: 12px;
    border-radius: 5px;
    .basket-modal-header{
        display: flex;
        align-items: center;
        justify-content:space-between;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
        .back-icon {
            cursor: pointer;
            opacity: 0.7;
            display: flex;
            align-items: center;
        }
        .header-text {
            opacity: 0.7;
            cursor: pointer;
        }
        svg {
            height: 18px;
        }
        .title{
            font-size: 20px;
            flex: 1 1 auto;
            text-align: center;
            padding-left: 12px;
        }
    }
    .baskets {
        flex: 1 1 auto;
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        overflow: auto;
    }
    .basket-element{
        border: 5px solid var(--border-color);
        border-radius: 5px;
        padding: 12px;
        display: flex;
        align-items: center;
        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
        .icon-btn {
            width: 16px;
            height: 16px;
            margin-right: 12px;
        }
        .basket-name{

        }
        .basket-price{

        }
        &.selected{
            border-color: var(--ten-color);
            .icon-btn {
                svg {
                    .svgStroke {
                        stroke: var(--ten-color)
                    }
                }
            }
        }
    }
}