.unit-select-root {
    display: flex;
    align-items: center;
    input {
        background-color: rgba(0, 0, 0, 0.09);
        color: var(--text-color);
        height: 60px;
        border: 2px solid var(--border-color);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        padding: 12px 10px;
        width: 60px;
        text-align: right;
        border-right: none;
        font-size: 18px;
    }
    .textField-root {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        .textField-base {
            border-radius: 0;
            border-left: none;
        }
    }
}