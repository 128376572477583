.order-dummy-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0.5em;
  border-radius: 5px;
  overflow: hidden;
  font-weight: bold;
  background-color: var(--thirty-color);
  .inner-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 0.5em;
      .svg-container{
        height: 50%;
        svg{
            height: 100%;
            .svgText{
                fill: var(--ten-color);
            }
        }
      }
      .text{
        text-align: center;
      }
  }
}
