.main-container {
    &.is-tablet {
        .login-img-container {
            height: 300px;
            margin-top: 20px;
        }
        .login-container {
            height: 500px;
            width: 300px;
            .login-date-time-container {
                display: none;
                .login-date-container {
                    font-size: 26px;
                }
                .login-time-container {
                    font-size: 22px;
                }
            }
        }
        .payment-numpad-root {
            .payment-numpad-header {
                font-size: 18px;
            }
            .payment-numpad-body {
                .numpad-btn {
                    .number {
                        font-size: 22px;
                    }
                }
            }
        } 
    }
}