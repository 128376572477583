.main-container {
    &.is-mobile {
        .modal.create-new-item-root-input-field-select-modal, .modal.multi-select-pos-design-InputFieldMultiSelect-modal {
            .modal-content {
              width: unset;
              height: unset;
              max-width: 200px;
              max-height: 250px;
              .filter-container {
                max-width: 200px;
                max-height: 250px;
              }
            }
        }
        .modal.create-new-item-modal {
            .modal-content {
                height: 100%;
                width: 100%;
                padding: 12px;
            }
        }
        .sizes-editor-root {
            .title {
                height: 28px;
            }
            .body {
                height: calc(100% - 28px - 72px);
                max-height: 100%;
                .sizes-container {
                    .size-row {
                        width: 100%;
                        .col {
                            width: 100%;
                            height: auto;
                            .row {
                                flex-wrap: wrap;
                                &.open {
                                    height: 180px;
                                }
                            }
                        }
                    }
                }
            }
            .footer {
                height: 48px;
            }
        }
        .header-left-side-floater {
            width: calc(100% - 24px);
            min-width: 250px;
        }
        .categories-container {
           width: calc(100% - 24px);
           height: calc(100% - 64px);
           overflow: auto;
           justify-content: center;
            .category-container {
                width: calc(100% - 50px);
            }
        }
        .modal.create-new-category-modal {
            .modal-content {
                width: 95%;
                height: 50%;
                .create-new-category-root {
                    width: 100%;
                    height: 100%;
                    padding: 24px;
                    overflow: auto;
                    display: flex;
                    flex-direction: column;
                    .create-new-category-container {
                        .new-category-single-data {
                            width: 100%;
                        }
                    }
                    .button-container {
                        margin-top: auto;
                    }
                }
            }
        }   
        .create-new-item-root {
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            .create-new-item-title {
                flex-shrink: 0;
            }
            .create-new-item-container {
                display: flex;
                flex-wrap: wrap;
                flex: 1 1 auto;
                align-content: flex-start;
                overflow: auto;
                .new-item-single-data {
                    width: 100%;
                    height: auto;
                }
            }
            .button-container {
                flex-shrink: 0;
            }
        }
        .center-root {
            padding-right: 0px;
            &.left-bar-closed {
                .swiper-controller {
                    width: 100%;
                    left: 0;
                }
            }
        }
        .order-items-container {
            flex-grow: 1;
            padding: 12px 0 80vh 12px;
            width: calc(100% - 12px);
            gap: 0;
            height: calc(100% - 110px);
            .order-item-container {
                flex-shrink: 0;
                margin: 0 6px 6px 0;
                width: calc(((100% - 12px) / var(--orderItemsColumns)) - 21px);
            }
        }
        .basket-item-container {
            width: calc(100% - 24px);
            .modifier-rows-container {
                width: calc(100% - 12px);
                .modifier-row {
                    .count {
                        
                    }
                }
            }
        }
        .modifier-root {
            padding-bottom: 0px;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            .header-container, .footer {
                flex-shrink: 0;
            }
            .body {
                height: calc(100% - 115px);
                overflow: auto;
                .modifier-sub-content {
                    max-height: unset;
                }
            }
            .header-container {
                height: 26px;
            }
            .footer {
               height: 60px;
               margin-top: 12px;
            }
        }
        .payment-container-root {
            flex-direction: column;
            width: 100%;
            overflow: auto;
            position: unset;
            justify-content: flex-start;
            padding: 12px;
            .slide-up-container {
                position: absolute;
                bottom: 80px;
                height: 163px;
                display: flex;
                overflow: hidden;
                flex-direction: column;
                width: calc(100% - 24px);
                border-radius: 10px;
                padding: 0 12px;
                transition: all 0.3s;
                &.open {
                    height: 50vh;
                    padding: 12px;
                }
            }
            .top-container {
                display: flex;
                align-items: center;
                gap: 12px;
                row-gap: 12px;
                column-gap: 12px;
                opacity: 0.6;
                .back-to-sell-icon {
                    svg {
                        height: 24px;
                    }
                }
            }
            .page-selector {
                display: flex;
                width: 100%;
                background-color: var(--thirty-color);
                border-radius: 5px;
                overflow: hidden;
                .element {
                    font-size: 18px;
                    width: 50%;
                    padding: 12px;
                    text-align: center;
                    transition: all 0.3s;
                    &.selected {
                        background-color: var(--ten-color);
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }
            .payment-methods-container {
                width: 100%;
                padding: 0;
                flex: 0 0 auto;
                display: flex;
                flex-direction: column;
                .payment-methods-header {
                    align-items: center;
                    gap: 12px;
                    row-gap: 12px;
                    column-gap: 12px;
                    flex: 0 0 48px;
                    .total-paid {
                        font-size: 26px;
                        font-weight: bold;
                        display: flex;
                        gap: 4px;
                        flex: 1 1 auto;
                    }
                }
                .payment-methods-body {
                    flex: 1 1 auto;
                    .details {
                        display: flex;
                        gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
                        overflow: auto;
                        height: 38px;
                        .methods-body-row {
                            margin: 0;
                            flex-shrink: 0;
                        }
                    }
                    .payments-needed {
                        margin: 0.5em 0;
                        font-weight: normal;
                    }
                }
            }
            .middle-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                overflow: auto;
                &.payment-types-on {
                    height: calc(100% - 330px);
                }
                &.payment-types-off {
                    height: calc(100% - 180px);
                }
                .payment-numpad-root {
                    width: 100%;
                    padding: 0 12px;
                    background-color: var(--sixty-color);
                    .payment-numpad-header {
                        font-size: 26px;
                        .header-title {
                            flex-direction: column;
                        }
                    }
                    .current-number {
                        padding: 18px 0;
                        width: 296px;
                        margin: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        padding-right: 50px;
                        svg {
                            height: 30px;
                            top: 15px;
                        }
                    }
                    .payment-numpad-body {
                        display: flex;
                        gap: 12px;
row-gap: 12px;
column-gap: 12px;
                        flex-wrap: wrap;
                        width: 296px;
                        margin: auto;
                        .numpad-btn {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 65px;
                            height: 65px;
                            padding: 12px;
                        }
                    }
                }
                .payment-methods-body {
                    height: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    .details {
                        flex-direction: column;
                        flex: 1 1 auto;
                        overflow: auto;
                        .methods-body-row {
                            width: 100%;
                        }
                    }
                }
            }
            .payments-summary {
                position: absolute;
                bottom: 12px;
                display: flex;
                background-color: var(--thirty-color);
                width: calc(100% - 24px);
                border-radius: 10px;
                height: 58px;
                .box {
                    padding: 12px 6px;
                    width: 33.33%;
                    .label {
                        font-size: 11px;
                    }
                    &.box-mid {
                        color: var(--ten-color);
                    }
                }
            }
            .payment-button-container-root {
                padding: 0;
                .payment-button {
                    &.selected {
                        border-color: transparent
                    }
                }
            }
        }
        .admin-view-root {
            padding: 12px;
            width: 100%;
            height: 100%;
        }
        .orderViewTopBar {
            display: none;
        }
        .orderViewCardContainer {
            flex-wrap: nowrap;
            padding: 6px;
            height: calc(100% - 12px);
            width: calc(100% - 12px);
            .order-card-root {
                width: 100%;
                .order-card-header {
                    flex: 0 0 24px;
                }
            }
        }
        .img-container {
            display: none;
        }
        .modal {
            .modal-content {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                padding: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
        .vip-modal-root {
            height: 100%;
            width: 100%;
            .body {
                flex-direction: column;
            }
            .footer {
                flex-direction: column;
                gap: 12px;
row-gap: 12px;
column-gap: 12px;
            }
        }
        .dashboard-root {
            height: calc(100% - 24px);
        }
        .dashboard-chart-container {
            width: calc(100% - 2em);
            padding: 0;
            .chart-container {
                padding: 0;
            }
        }
        .dashboard-box {
            margin: 0 0.5em 0.5em 0;
            width: 90%;
            .okeoke-data-table {
                padding: 0;
            }
        }
        .transactions-root {
            //display: block;
            //flex-direction: column;
            width: 100%;
            height: 100%;
            overflow: auto;
            .orders-root {
                height: 100%;
                width: 300px;
                min-width: unset;
                max-width: unset;
                .orders-container {
                    .order-row {
                        width: calc(100% - 40px);
                    }
                }
            }
            .tansactions-filter-root {
                height: unset;
                width: 100%;
                .group-container {
                    .options-container {
                        .option {
                            width: 100%;
                            &.no-padding {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .previous-orders-display-root {
                flex-grow: unset;
                .orders-container {
                    flex: unset;
                    .order-row {
                        width: calc(100% - 35px);
                    }
                }
            }
        }
        .selected-order-container {
            height: calc(100% - 24px);
            min-width: 250px;
        }
        .login-outer-container {
            flex-direction: column;
            position: relative;
            width: 100%;
            .left-side {
                display: none;
            }
            .login-container {
                margin: auto;
                max-height: 100%;
                min-width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
                position: relative;
                .login-img-container {
                    display: none;
                }
                svg {
                    width: 100%;
                }
                .login-pin-container {
                    height: calc(100% - 83px - 200px);
                    .login-pin-input-container {
                        margin: 12px auto;
                    }
                    .login-pin-numbers {
                        margin: auto;
                    }
                }
            }
        }
        .login-container {
            margin-top: 120px;
        }
        .login-date-time-container {
            height: 60px;
            .login-date-container {
                font-size: 28px;
            }
            .login-time-container {
                font-size: 24px;
            }
        }
        .options-modal {
            .modal-body {
                flex-direction: column;
            }
        }
        .options-modal {
            .option-list {
                .option {
                    gap: 10px;
                    .action-buttons {
                        width: 40px;
                    }
                }
            }
        }
        .order-summary-container {
            height: calc(100% - 198px);
            &.open {
                height: calc(100% - 355px);
                margin-bottom: 12px;
            }
        }
        .order-summary-payment-container {
            border: 1px solid var(--border-color);
            .btn {
                width: calc(100% - 32px);
            }
            &.submit-on {
                .btn {
                    width: calc(50% - 42px);
                }
            }
            
        }
        .payment-container-root {
            gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
            height: 100%;
            .middle-container {
                flex-direction: row;
                .payment-numpad-root {
                    //width:90%;
                }
            }
        }
        .payment-methods-container {
            height: 100%;
            .payment-methods-body {
                .total-price {
                    font-size: 18px;
                }
                .total-paid {
                    margin: 12px 0;
                }
                .details {
                    margin-top: 0;
                }
            }
        }
        .basket-modal-root {
            width: 100%;
            height: 100%;
        }
        .tip-modal {
            .modal-bgLayout {
                background-color: var(--sixty-color);
            }
        }
        .tip-modal-content {
            overflow: hidden;
            width: 100%;
            height: 100%;
            .body {
                flex-direction: column;
            }
            .percents-container {
                flex-direction: row;
                flex-wrap: wrap;
                .btn {
                    margin: 0 6px 6px 0;
                }
            }
            .amounts-container {
                flex-direction: row;
                flex-wrap: wrap;
                .btn {
                    margin: 0 6px 6px 0;
                }
            }
            .numpad-container {
                width: 100%;
            }
        }
        .dialog-title {
            font-size: 22px;
        }
        .device-container-root {
            width: 95%;
        }
        .discount-modal-root {
            width: 100%;
            height: 100%;
            padding: 12px 12px 16px;
            .body {
                flex-direction: column-reverse;
                height: calc(100% - 21px - 48px - 24px);
                .item-list {
                    width: 100%;
                    height: calc(100% - 200px);
                }
                .discount-list {
                    width: 100%;
                    height: 200px;
                }
            }
        }
        .device-customization {
            height: 100%;
            flex-direction: column;
            .col {
                width: 100%;
            }
            .item-column-btn {
                &.font-size, &.font-weight, &.other {
                    width: 100%;

                }
            }
        }
        .layout-presets {
            overflow: hidden;
            .presets-container {
                overflow: auto;
            }
        }
        .admin-button {
            width: 100%;
        }
        .fiscat-admin-root {
            .status-container {
                width: calc(100% - 2em);
            }
            .action-btn-container {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
row-gap: 12px;
column-gap: 12px;
            }
        }
        .fiscal-printer-control-panel-container {
            padding: 12px;
            height: calc(100% - 24px);
            overflow: auto;
        }
        .order-view-top-bar {
            display: none;
        }
        .order-view-card-container {
            width: calc(100% - 24px);
            flex-direction: row;
            padding-top: 12px;
            .order-card-root {
                width: 100%;
            }
        }
        .item-sales-container {
            padding: 12px;
            height: calc(100% - 24px);
            overflow: auto;
            .table-container {
                flex-direction: column;
            }
        }
        .reports-container {
            padding: 12px;
            height: 100%;
            .footer {
                display: none;
            }
            .chart-container {
                display: none;
            }
        }
        .box-container {
            .box {
                width: 100%;
            }
        }
        .summary-root {
            height: calc(100% - 60px);
            border-left: 2px solid var(--sixty-color);
        }
        .basket-modal-root {
            .basket-modal-header {
                gap: 16px;
            }
        }
        .order-note-modal-field {
            width: 100%;
            padding: 12px;
        }
        .item-editor-container-root {
            max-width: 100%;
            height: calc(100% - 24px);
        }
        .header-main-container {
            justify-content: flex-start;
        }
        .sell-header-root {
            min-width: 120px;
            .number-row-container-root {
                display: none;
            }
        }
        .sell-header-root {
            min-width: 90px;
            .search-field-root {
                min-width: 90px;
                input {
                    width: 90px;
                    padding: 12px 25px 12px 6px;
                }
            }
        }
        .order-summary-container {
            .sell-not-allowed {
                height: calc(100% - 48px);
                width: calc(100% - 48px);
            }
        }
        .left-side-root {
            z-index: 997;
        }
        .menu-toggler {
            z-index: 997;
            background-color: var(--header-background);
        }
        .open-orders-header-root {
            .btn {
                padding: 4px;
                min-width: 36px;
            }
        }
        .order-view-content {
            flex-direction: column;
            .table-list {
                width: 100%;
                height: 100px;
                &.open {
                    min-height: 100px;
                }
                .table-list-container {
                    flex-direction: row;
                    .table-card {
                        min-width: 150px;
                        display: flex;
                        flex-direction: column;
                        .table-name {
                            flex: 1 1 auto;
                        }
                    }
                }
            }
            .order-view-right-side {
                overflow: auto;
                .order-view-table-header {
                    flex-direction: column;
                }
                .order-view-card-container {
                    flex: none;
                }
            }
        }
        .printer-modal {
            max-height: 100%;
            padding: 6px;
            .content {
                max-height: 100%;
            }
            .modal-body {
                padding: 6px;
            }
        }
        .fiscal-printer-overlay {
            width: calc(100% - 72px);
        }
    }
    .change-pin-container {
        position: absolute;
        bottom: 12px;
        background-color: var(--thirty-color);
        padding: 4px;
    }
    .modal.select-modal {
        .modal-content {
            width: auto;
            height: auto;
        }
    }
}
.device-login-main {
    &.mobile {
        .left-side {
            display: none;
        }
        .right-side {
            width: 100%;
            min-width: 200px;
            .device-login-welcome {
                width: 100%;
                min-width: 200px;
            }
        }
        .device-activation-root {
            .left-side {
                display: flex;
                flex-direction: column;
                width: 100%;
                min-height: 305px;
                height: auto;
                gap: 6px;
        row-gap: 6px;
        column-gap: 6px;
                .logo {
                    width: 80%;
                    margin: auto;
                    img {
                        object-fit: contain;
                    }
                }
                .device-activation-request-code {
                    min-height: 60px;
                    margin-bottom: 0;
                }
            }
        }
    }
}
