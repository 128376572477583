.basket-item-container {
  display: flex;
  flex-shrink: 0;
  background: var(--sixty-color);
  padding: 6px 12px 0px 6px;
  border-radius: 5px;
  filter: var(--orderItemShadow);
  margin: 1px 4px;
  &.notes {
    padding: 6px 12px;
  }
}
.bin-container {
  flex: 0 0 32px;
  padding: 6px 0 0;
  .icon-btn {
    height: 21px;
  }
}
.item-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0 0 6px;
  &.selected {
    background: var(--ten-color);
    .icon-btn {
      svg {
        .svgText {
          fill: white;
          stroke: white;
        }
      }
    }
  }
  .top-row {
    display: flex;
    align-items: center;
    gap: 3px;
    .icon-btn {
      height: 21px;
    }
    .name {
      flex: 1 1 auto;
      font-weight: 500;
      display: flex;
      align-items: center;
      padding: 0 4px;
      overflow: hidden;
      font-size: 16px;
      overflow-wrap: break-word;
    }
    .count{
      display: flex;
      justify-content: flex-end;
      flex: 0 0 50px;
      height: 26px;
      .textField-root {
        min-width: unset;
        border-radius: 5px;
        .textField-base {
          color: var(--text-color);
          padding: unset;
          height: 100%;
          background-color: unset;
          font-size: unset;
          .textField-input {
            text-align: center;
            padding: 2px;
            display: flex;
            height: 100%;
            font-size: 14px;
          }
        }
      }
    }
    .price {
      font-weight: 500;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex: 0 0 71px;
      height: 26px;
      font-size: 14px;
      color: var(--ten-color);
    }
  }
  .modifier-rows-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 0.25px;
    font-size: 12px;
    padding-left: 12px;
    opacity: 0.9;
    .icon-btn {
      height: 18px;
    }
    .take-away-row {
      display: flex;
      align-items: center;
    }
    .modifier-row {
      display: flex;
      width: 100%;
      position: relative;
      &.notes {
        border: 1px solid var(--border-color);
        border-left: none;
        border-right: none;
        padding: 5px 0;
        margin: 3px 0;
      }
      &.border-top {
        border-top: 1px solid var(--border-color);
        margin-top: 3px;
        padding-top: 3px;
      }
      &.not-available {
        opacity: 75%;
        filter: brightness(0.8);
      }
      .name {
        flex-grow: 1;
      }
      .count {
        // margin-right: 2.75em;
        // width: 3em;
        min-width: 50px;
        display: flex;
        justify-content: center;
        .number-container{
          display: flex;
          justify-content: center;
          width: 2.8em
        }
      }
      .price {
        // min-width: 100px;
        min-width: 68px;
        display: flex;
        justify-content: flex-end;
        //color: var(--ten-color);
      }
      &.discount {
        margin-top: 1em;
        font-size: 12px;
      }
    }
  }
  .bottom-row {
    display: flex;
    .textfield-container.textField-root {
      display: flex;
      flex-grow: 1;
      align-items: center;
      border-radius: 5px;
      .textField-label {
        font-size: 1em;
        font-family: "Barlow";
        list-style: unset;
        color: var(--icon-color);
        transform: translate(1em, 1.2em) scale(1);
        &.shrink{
          transform: translate(0.5em, 0.6em) scale(0.7);
        }
      }
      .textField-base {
        width: 100%;
        color: white;
        font-size: unset;
        .textField-input{
          padding: 1.75em 0.5em 0.6em;
        }
      }
    }
    .icon-btn {
      height: 16px;
      width: 16px;
    }
  }
}

.animation-disappear {
  opacity: 0;
  transition: opacity 600ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
