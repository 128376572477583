.input-field-select-main-container.textField-root {
  position: relative;
  .textField-base {
    cursor: pointer;
    display: block;
    padding: 27px 12px 10px;
  }  
}
.inputFieldSelectList {
  display: flex;
  flex-direction: column;
  max-height: 30vh;
  overflow: auto;
  .list-element {
    cursor: pointer;
    padding: 0.5em;
    border-radius: 5px;
    margin-bottom: 0.5em;
    border: 1px solid var(--border-color);
    background-color: var(--sixty-color);
    &.selected {
      background-color: var(--ten-color);
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
