.search-field-root {
    display: flex;
    align-items: center;
    min-width: 120px;
    height: 100%;
    position: relative;
    svg {
        
    }
    .search-icon, .clear-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
    }
    .search-icon {
        left: 12px;
        svg {
            height: 26px;
        }
    }
    .clear-icon {
        cursor: pointer;
        right: 12px;
        svg {
            height: 20px;
        }
    }
    input {
        height: 100%;
        width: 145px;
        background-color: var(--thirty-color);
        border: 1px solid var(--border-color);
        border-radius: 5px;
        color: var(--text-color);
        padding: 0 44px;
    }
}