.login-outer-container {
  display: flex;
  width: calc(100% - 300px);
  height: calc(100% - var(--header-height));
  justify-content: center;
  align-items: center;
  margin-right: auto;
  margin-top: auto;

  .left-side {
    margin-right: 24px;
    width: calc(100% - 300px);
  }
}

.login-img-container {
  width: 300px;
  margin: 70px 0 0 auto;

  img {
    width: 100%;
    display: block;
    margin: auto 0 auto auto;
  }
}

.logo-container {
  display: flex;
  justify-content: flex-end;

  img {
    height: 70px;
  }
}

.exit-btn {
  position: fixed;
  bottom: 12px;
  right: 85px;
  height: 48px;
  width: 48px;
  padding: 6px;
  border: 2px solid #393c49;
  background-color: #1f2023;
  z-index: 50;

  svg {
    width: 26px;
    height: 26px;

    .svgFill {
      fill: #fff;
    }

    .svgStroke {
      stroke: #fff;
    }
  }
}

.login-container {
  padding: 6px 12px;
  max-width: 300px;
  min-width: 300px;
  max-height: 500px;
  min-height: 500px;
  overflow: auto;
  position: relative;
}

.login-panel {
  .location-name {
    text-align: center;
    font-size: 18px;
    z-index: 5;
  }

  .device-name {
    text-align: center;
    z-index: 5;
  }

  .login-date-time-container {
    height: 58px;
    overflow: hidden;
    z-index: 5;

    .login-date-container,
    .login-time-container {
      text-align: center;
    }

    .login-date-container {
      font-size: 28px;
    }

    .login-time-container {
      font-size: 22px;
    }
  }

  .start-btn {
    z-index: 5;
    background-color: var(--ten-color);
    color: #fff;
    padding: 12px 22px;
    font-size: 26px;
    text-align: center;
    margin: 24px;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .login-pin-container {
    height: calc(100% - 83px - 24px);

    .login-pin-input-container {
      margin: 12px auto;
      width: 204px;
      height: 48px;

      .textField-root {
        opacity: 1;
      }

      .login-input-field {
        height: 48px;

        .textField-base {
          height: 48px;

          input {
            color: var(--text-color);
            text-align: center;
            font-size: 32px;
            padding: 12px;
          }
        }
      }
    }
  }
}

.change-pin-container {
  width: 204px;
  margin: 12px auto 0;
  height: 20px;
  text-decoration: underline;
  font-size: 14px;
  padding-left: 6px;
  cursor: pointer;
}

.new-pin-modal {
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    row-gap: 12px;
    column-gap: 12px;
  }

  .body {
    .input-field {
      background-color: rgba(0, 0, 0, 0.09);
      border: 1px solid var(--border-color);
      padding: 12px;
      border-radius: 5px;
      margin: auto;
      display: block;
      color: var(--text-color);
    }
  }

  .actions {
    display: flex;
    justify-content: space-around;
    gap: 12px;
    row-gap: 12px;
    column-gap: 12px;
  }
}

.flex-gap-not-supported {
  .login-pin-numbers {
    width: 216px;

    .login-button {
      margin: 6px;
    }
  }
}

.login-pin-numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  row-gap: 12px;
  column-gap: 12px;
  width: 204px;
  margin: auto;

  .login-button {
    width: 60px;
    height: 60px;
    padding: 12px;
    font-weight: 700;
    border-radius: 10px;
    border: 2px solid #393c49;
    position: relative;
    transition: 0.3s opacity;
    opacity: 1;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--sixty-color);

    &:active {
      transition: 0.3s opacity;
      opacity: 0.5;
    }

    &.highlighted {
      background-color: var(--ten-color);
    }

    .inner-container {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.ok-btn {
        font-size: 22px;
      }
    }

    svg {
      width: 24px;
      height: 24px;

      .svgText {
        fill: var(--text-color);
      }
    }
  }
}