.dummy-modal-root{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    row-gap: 20px;
    .dummy-modal-title{
        font-weight: 700;
    }
    .dummy-modal-body{
        display: flex;
        justify-content:center;
        font-weight: 500;
    }
}