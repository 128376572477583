.work-day-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
row-gap: 12px;
column-gap: 12px;
    .title {
        font-size: 26px;
    }
    .buttons-container {
        display: flex;
        justify-content: center;
        gap: 12px;
row-gap: 12px;
column-gap: 12px;
        margin-top: 12px;
    }
    input {
        background-color: #fff;
    }
}