.external-payment-items {
    width: 100%;
    height: 100%;
    padding: 12px;
    .external-payment-order {
        .order-header {
            font-size: 12px;
            opacity: 0.6;
            display: flex;
            gap: 6px;
        }
        .item-outer-container {
            display: flex;
            gap: 6px;
            .basket-item-container {
                flex: 1 1 auto;
                transition: all 0.3s;
                &.passive {
                    opacity: 0.1;
                }
            }
            .actions {
                display: flex;
                align-items: center;
                gap: 12px;
                .action-btn {
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--thirty-color);
                    border-radius: 5px;
                    transition: all 0.3s;
                    &.passive {
                        opacity: 0.1;
                    }
                }
                svg {
                    height: 16px;
                    width: 16px;
                    .svgFill {
                        //fill: var(--ten-color);
                    }
                    .svgStroke {
                        //stroke: var(--ten-color);
                    }
                }
            }
        }
    }
}
.item-payments {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .items-container {
        flex: 1 1 auto;
        .item-container {
            flex-direction: row;
            justify-content: flex-start;
            gap: 12px;
            align-items: center;
            .item-name {
                flex: 1 1 auto;
            }
            .actions {
                display: flex;
                gap: 12px;
                .action-btn {
                    width: 26px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--thirty-color);
                    border-radius: 5px;
                }
            }
        }
    }
    .pay-btn-container {
        background-color: var(--ten-color);
        border-radius: 5px;
        padding: 6px;
        text-align: center;
        transition: all 0.3s;
        &.disabled {
            opacity: 0.5;
        }
    }
}
.external-payment-items {
    display: flex;
    flex-direction: column;
    gap: 6px;
    overflow: auto;
}