.nav-loading-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
  z-index: 9999;
}

.nav-loading-text {
  margin-right: 1rem;
  padding: 0.8rem;
  border-radius: 5px;
  border-style: solid;
  color: azure;
  font-size: xx-large;
}

.nav-modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.nav-modal-container .modal {
  opacity:1;
  width: 30vw;
  height: auto;
  background-color: #fff;
  padding: 2rem;
  display: block;
  top: unset;
  left: unset;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.671);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.nav-modal-container .modal_header {
  position: relative;
  border-bottom: 1px solid #dddddd;
}

.nav-modal-container .modal_header_title {
  text-align: center;
}

.nav-modal-container .modal_header .close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
}

.nav-modal-container .modal_header .close img {
  width: 1rem;
  height: auto;
  transition: all 0.3s;
}

.nav-modal-container .modal_header .close:hover img {
  transform: scale(1.1);
}

.nav-modal-container .modal_content {
  border-bottom: 1px solid #dddddd;
  padding: 2rem 0;
  display: flex;
  align-items: center;
}

.nav-modal-container .modal_footer {
  padding: 2rem 0;
  padding-bottom: 0;
}

.nav-modal-container .modal_footer button {
  float: right;
  padding: 0.5rem;
  border-radius: 5px;
}

.nav-modal-container .modal_footer .modal_close {
  background-color: transparent;
  font-weight: 600;
}

.nav-modal-container .modal_footer .submit {
  background-color: #364348;
  // color: #fff;
  color: black;
}

button.nav-admin {
  margin-right: 1rem;
  padding: 0.8rem;
  border-radius: 5px;
}

button:hover.nav-admin {
  margin-right: 1rem;
  padding: 0.8rem;
  border-radius: 5px;
  transform: scale(1.1);
}

input.nav-admin {
  margin-right: 1rem;
  padding: 0.5rem;
  border-radius: 5px;
}