html, body, #root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--thirty-color)
}
.demo-mode-bar, .sandbox-mode-bar {
  height: 0;
  overflow: hidden;
  background-color: #ffab00;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: height 0.3s ease-in-out;
  font-size: 14px;
  text-align: center;
  &.visible {
    height: 32px;
  }
}
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  background: var(--thirty-color);
  transition: height 0.3s ease-in-out;
  &.demo-mode, &.sandbox-mode {
    height: calc(100% - 32px);
    transition: height 0.3s ease-in-out;
    .menu-toggler {
      top: 48px;
      transition: top 0.3s ease-in-out;
    }
  }
}
.left-side-root {
  height: calc(100% - var(--header-height));
  display: flex;
  position: absolute;
  left: 0;
  top: var(--header-height);
  z-index: 0;
  flex-direction: column;
  background: var(--thirty-color);
  overflow: hidden;
  z-index: 999;
  transition: all 0.3s;
  &.closed {
    width: 0;
  }
  &.open {
    width: var(--left-side-width);
  }
}
.center-root {
  width: 100%;
  height: 100%;
  padding-left: var(--left-side-width);
  padding-top: var(--header-height);
  overflow: hidden;
  background: var(--sixty-color);
  transition: all 0.3s;
  &.left-bar-closed {
    padding-left: 0;
  }
}
.right-side-root {
  width: var(--right-side-width);
  height: calc(100% - var(--header-height));
  padding: 0 12px;
  background: var(--thirty-color);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  &.empty {
    width: 0;
    padding: 0;
  }
}