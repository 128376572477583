.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  &.create-new-item-root-input-field-select-modal {
    .modal-content {
      width: unset;
      height: unset;
      overflow: auto;
      max-width: 40vw;
      max-height: 40vh;
    }
  }
  &.full-screen {
    .modal-content {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .close-icon {
    position: absolute;
    top: 0.25em;
    right: 0.25em;
    height: 28px;
    width: 28px;
    z-index: 998;
    cursor: pointer;
  }
  .modal-title {
    text-align: center;
    margin-bottom: 1em;
  }
  .modal-bgLayout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 10;
  }
  .modal-content {
    z-index: 20;
    position: relative;
    max-height: 90%;
    max-width: 90%;
    overflow: hidden;
    background: var(--thirty-color);
    color: var(--text-color);
    border-radius: 5px;
    padding: 12px;
    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      border-bottom: 1px solid var(--grey);
      .modal-title {
        margin: 0;
        font-size: 2em;
        font-weight: bold;
        padding-right: 30px;
      }
      .modal-actions {
        display: flex;
        align-items: center;
        .btn {
          margin-left: 0.5em;
        }
      }
    }
    .fields {
      display: flex;
      flex-direction: column;
      .textField-root,
      .okeoke-input-switch {
        margin: 0.5em auto;
      }
    }
  }
}
.modal-appear-effect {
  animation: 0.3s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

.pos-modal-base-skin {
  .modal-content {
    background: transparent;
  }
}

.plugged-modal {
  display: flex;
  flex-direction: column;
  gap: 12px;
row-gap: 12px;
column-gap: 12px;
  align-items: center;
  justify-content: center;
  .btn {
    margin: 12px;
  }
}

.print-error-modal {
  .printer-container {
    margin-bottom: 12px;
    .printer-name {
      font-size: 18px;
      font-weight: bold;
    }
  }
}