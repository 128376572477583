.btn {
    height: 3em;
    cursor: pointer;
    display: inline-flex;
    outline: 0;
    padding: 6px 16px;
    position: relative;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--btn-bg-color);
    color: var(--btn-text-color);
    border-radius: 5px;
    overflow: hidden;
    min-width: 48px;
    flex-shrink: 0;
    font-weight: bold;
    transition: all 0.3s;
    &.inactive {
        background: var(--btn-bg-color);
        opacity: 0.6;
        cursor: not-allowed;
    }
    svg {
        .svgText{
            fill: var(--btn-text-color);
            stroke: var(--btn-text-color);
        }
    }
    .icon-btn {
        svg {
            .svgText{
                fill: var(--btn-text-color);
                stroke: var(--btn-text-color);
            }
            .svgFill{
                fill: var(--btn-text-color);
            }
            .svgStroke{
                stroke: var(--btn-text-color);
            }
        }
    }
}
.btn-highlighted {
    background: var(--ten-color);
    color: var(--btn2-text-color);
    font-weight: 700;
    svg {
        .svgText{
            fill: var(--btn2-text-color);
            stroke: var(--btn2-text-color);
        }
        .svgFill{
            fill: var(--btn2-text-color);
        }
        .svgStroke{
            stroke: var(--btn2-text-color);
        }
    }
    .icon-btn {
        svg {
            .svgText{
                fill: var(--btn2-text-color);
                stroke: var(--btn2-text-color);
            }
            .svgFill{
                fill: var(--btn2-text-color);
            }
            .svgStroke{
                stroke: var(--btn2-text-color);
            }
        }
    }
}
.numpad-btn {
    cursor: pointer;
    width: 80px;
}
.icon-btn {
    width: 2em;
    height: 2em;
    padding: 0;
    position: relative;
    cursor: pointer;
    .icon-btn-badge {
        background: rgba(255, 0, 0, 0.7);
        color: white;
        font-weight: bold;
        border-radius: 100%;
        position: absolute;
        height: 20px;
        width: 20px;
        top: -6px;
        right: -3px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
    }
    svg {
        height: 100%;
        width: 100%;
        .svgText{
            fill: var(--icon-color);
            stroke: var(--icon-color);
        }
        .svgFill{
            fill: var(--icon-color);
        }
        .svgStroke{
            stroke: var(--icon-color);
        }
    }
    &.selected{
        svg {
            .svgText{
                fill: var(--ten-color);
                stroke: var(--ten-color);
            }
            .svgFill{
                fill: var(--ten-color);
            }
            .svgStroke{
                stroke: var(--ten-color);
            }
        }   
    }
}    
.btn-cancel {
    background-color: var(--red);
    color: #fff;
}
.btn-save {
    background-color: var(--green);
    color: #fff;
}
